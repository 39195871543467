import axios from 'axios';
import Vue from 'vue';
// import store from '@/store';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
  },
});

Vue.prototype.$api = api;

api.interceptors.response.use(
  (response) => {
    console.debug(`HTTP < ${response.status}`);

    return Promise.resolve(response);
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status) {
      console.debug(`HTTP < ${status}`);
    } else {
      console.debug('HTTP < No response');
      console.error(error);
    }

    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if ((error.response && error.response.status === 401) || error.response.status === 403) {
      // store.dispatch('user/logout');
    }
    return Promise.reject(error);
  },
);

export default api;
